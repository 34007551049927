import colorlog from "@libs/colorlog";
import { AlertColor } from "@mui/material";
import React from "react";

const log = colorlog("components");

interface AlertContext {
  isOpen: boolean;
  message: string;
  severity: AlertColor;
  setAlert: any
}

// Create a Context
export const AlertContext = React.createContext<AlertContext>({
  isOpen: false,
  message: "some text for the alert",
  severity: "warning",
  setAlert: (message: string, severity: AlertColor) =>
    log.error("AlertContext::setAlert: not implemented:"/*, message, severity*/)
});
