import { useContext } from "react";
import Image from "next/image";

import { AppContext } from "./contexts/AppContext";

const RedSiftLoadingIndicator = ({
  width = 100,
  show = false,
  center = true,
}) => {
  const ctxApp = useContext(AppContext);

  return (
    <div
      style={{
        display: ctxApp.isLoading || show ? "flex" : "none",
        justifyContent: "center",
        height: center ? "100vh" : "none",
      }}
    >
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        alt="loading-indicator"
        width={width}
        src="/red-sift-loading-indicator.svg"
      />
    </div>
  );
};

export default RedSiftLoadingIndicator;
