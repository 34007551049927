import CustomAlert from "@components/common/custom/CustomAlert";
import { Snackbar } from "@mui/material";
import { useContext } from "react";
import { AlertContext } from "../contexts/AlertContext";

const GlobalAlert = () => {
  const ctxAlert = useContext(AlertContext);
  const {isOpen, message, severity} = ctxAlert;
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={e => ctxAlert.setAlert('', severity)}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <CustomAlert
        onClose={e => ctxAlert.setAlert('', severity)}
        severity={severity}
        sx={{ width: "100%" }}
      >
        {message}
      </CustomAlert>
    </Snackbar>
  );
}

export default GlobalAlert;
