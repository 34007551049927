import { useState } from "react";
import { PRODUCT_NAMES } from "../../libs/products";
import { AppContext } from "../contexts/AppContext";

const AppProvider = ({ children }: any) => {
  const [isLoading, setLoading] = useState(false);
  const [product, setProduct] = useState("red-sift");

  return (
    <AppContext.Provider
      value={{
        isLoading: isLoading,
        setLoading: (loading: boolean) => {
          setLoading(loading);
        },
        getProduct: () => product,
        setProduct: (product: string) => {
          const p = PRODUCT_NAMES[product] ? product : "red-sift";
          setProduct(p);
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
