import colorlog from "@libs/colorlog";
import { getApps, initializeApp } from "firebase/app";
import {
  Auth,
  connectAuthEmulator,
  getAuth,
  signInWithPopup
} from "firebase/auth";
import CONFIG from "../../config/default.json";

const log = colorlog("libs");

// TODO: set it up for local development with emulator
const firebaseAuthEmulatorHost = null;

export const initFirebaseClientSDK = () => {
  if (!getApps().length) {
    initializeApp(CONFIG.firebase);
  }
  // If the user has provided the firebaseAuthEmulatorHost address, set the emulator
  if (firebaseAuthEmulatorHost) {
    const auth = getAuth();
    connectAuthEmulator(auth, `http://${firebaseAuthEmulatorHost}`);
  }
};

export const signUserIn = async (
  auth: Auth,
  provider: any,
  tenantId?: string
) => {
  let user: any, error: any;
  log.debug("signUserIn");
  try {
    if (tenantId) {
      auth.tenantId = tenantId;
    }
    const { user: u } = await signInWithPopup(auth, provider);
    return { user: u, error: null };
  } catch (e: any) {
    log.error("signUserIn::", e);
    return { user: null, error: e };
  }
  return { user, error };
};
