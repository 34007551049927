import colorlog from "@libs/colorlog";
import React from "react";

const log = colorlog("components/context/AppContext");

// Create a Context
export const AppContext = React.createContext({
  isLoading: false,
  setLoading: (isLoading: boolean) =>
    log.error("setLoading: not implemented", isLoading),
  getProduct: (): string => {
    log.error("getProduct: not implemented");
    return "getProduct: not implemented";
  },
  setProduct: (product: string) =>
    log.error("setProduct: not implemented", product),
});
