import colors from "colors";
import loglevel, { LogLevelDesc } from "loglevel";
import loglevelPrefix from "loglevel-plugin-prefix";

/**
 *
 * usage:
 *      const log = require('loglevel-colors')(<component name>);
 *      log.debug('log');
 *      log.warn('some more logs);
 *
 * output:
 *      given a component name of 'Main':
 *      [Main] DEBUG: log
 *      [Main] WARN: some more logs
 */

let reqId: string;

export const setRequestContext = () => {
  reqId = Math.random().toString(36).substring(7);
};

const colorlog = (
  component: string,
  level = process.env.LOGLEVEL || "INFO"
) => {
  const outputColors: any = {
    TRACE: colors.magenta,
    DEBUG: colors.cyan,
    INFO: colors.blue,
    WARN: colors.yellow,
    ERROR: colors.red,
  };

  loglevelPrefix.reg(loglevel);
  loglevelPrefix.apply(loglevel, {
    levelFormatter: (level: string) => {
      return level.toUpperCase();
    },
    format: (level: string, name: string | undefined) => {
      return `${outputColors[level](level)} [${colors.blue(
        name || ""
      )}]: [${reqId}]:`;
    },
  });
  const log = loglevel.getLogger(component);

  log.setLevel(level as LogLevelDesc);
  return log;
};

export default colorlog;
