import { AlertColor } from "@mui/material";
import { useState } from "react";
import { AlertContext } from "../contexts/AlertContext";

const AlertProvider = ({ children }: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("some text");
  const [severity, setSeverity] = useState<AlertColor>("error");
  return (
    <AlertContext.Provider
      value={{
        isOpen: isOpen,
        message: message,
        severity: severity,
        setAlert: (message: string, severity: AlertColor) => {
          setIsOpen(message ? true : false);
          setMessage(message);
          setSeverity(severity);
        },
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
