import { Text } from "@redsift/design-system";
import Image from "next/image";
import colorlog from "./colorlog";

const log = colorlog("libs");

export const PRODUCT_NAMES: any = {
  ondmarc: "Red Sift OnDMARC",
  "red-sift": "Red Sift",
  "domain-protection": "Cisco Domain Protection",
  "pulse-platform": "Red Sift Pulse Platform",
};

export const GUID_MAP: { [key: string]: string } = {
  "Qvpg7LTaTMw1-Xfz1puIhH1sgs7IjV6mGb1g20XQ-5DDhyxJ.1": "ondmarc",
  "INrw29d28M66gDWAcp522XHMTc1kFuxIlTdpoSLCn-drkCDg.1": "oninbox",
  "o1aBPkOLYJn9f3ixUOrZ0qmUDjiS0SGbo1dDLz8DRrVUGvuU.1": "ondomain",
  "1TpUvROtKGDhePFVJBYVINLJWGg1JDKWlizlAj0wmrruBq4N.1": "oninbox-manager",
};

export const PRODUCT_MAP: { [key: string]: string } = {
  ondmarc: "Qvpg7LTaTMw1-Xfz1puIhH1sgs7IjV6mGb1g20XQ-5DDhyxJ.1",
  oninbox: "INrw29d28M66gDWAcp522XHMTc1kFuxIlTdpoSLCn-drkCDg.1",
  ondomain: "o1aBPkOLYJn9f3ixUOrZ0qmUDjiS0SGbo1dDLz8DRrVUGvuU.1",
  "oninbox-manager": "1TpUvROtKGDhePFVJBYVINLJWGg1JDKWlizlAj0wmrruBq4N.1",
  "domain-protection": "Qvpg7LTaTMw1-Xfz1puIhH1sgs7IjV6mGb1g20XQ-5DDhyxJ.1",
};

export const getProductName = (product: string) => {
  if (product && PRODUCT_NAMES[product]) {
    return PRODUCT_NAMES[product];
  } else {
    log.error("getProductName::unconfigured product:", product);
    return PRODUCT_NAMES["red-sift"];
  }
};


export const DefaultTagline = () => (
  <Text
    as="p"
    style={{
      marginTop: "20px",
      lineHeight: 1,
      display: "inline-block",
      color: "white",
      fontSize: "20px",
      paddingLeft: "2px",
      fontFamily: "Poppins",
    }}
  >
    Internet-scale cybersecurity intelligence meets trusted AI
  </Text>
);

export const LOGIN_PAGE_CONFIG: any = {
  ondmarc: {
    name: "Red Sift OnDMARC",
    Logo: function logo() {
      return (
        <div>
          <Image
            src="/ondmarc-logo.svg"
            width={200}
            height={53}
            alt="OnDMARC by Red Sift"
          />
        </div>
      );
    },
    Logomark: function logomark() {
      return (
        <Image src="/ondmarc-logo.svg" width={20} height={20} alt="OnDMARC" />
      );
    },
    TagLine: function tagLine() {
      return (
        <div
          style={{
            marginTop: "20px",
            lineHeight: 1,
            display: "inline-block",
            color: "white",
            fontSize: "20px",
            paddingLeft: "2px",
            fontFamily: "Poppins",
          }}
        >
          Protect against phishing and BEC attacks
        </div>
      );
    },
  },
  "domain-protection": {
    name: "Cisco Domain Protection",
    Logo: function logo() {
      return (
        <div>
          <Image
            src="/cisco-domain-protect.svg"
            width={120}
            height={53}
            alt="Cisco Domain Protect"
          ></Image>
          <Image
            src="/ondmarc-logo.svg"
            width={200}
            height={53}
            alt="OnDMARC by Red Sift"
          />
        </div>
      );
    },
    TagLine: function tagLine() {
      return (
        <div
          style={{
            marginTop: "20px",
            lineHeight: 1,
            display: "inline-block",
            color: "white",
            fontSize: "20px",
            paddingLeft: "2px",
            fontFamily: "Poppins",
          }}
        >
          Protect against phishing and BEC attacks
        </div>
      );
    },
  },
  "pulse-platform": {
    Logo: function logo() {
      return (
        <Image
          src="/red-sift-pulse-platform.svg"
          width={200}
          height={53}
          alt="Red Sift Pulse Platform"
        />
      );
    },
    Logomark: function logomark() {
      return (
        <Image
          src="/red-sift-pulse-platform.svg"
          width={20}
          height={20}
          alt="Red Sift"
        />
      );
    },
  },
};
